<template>
    <div>
        <v-skeleton-loader type="list-item-three-line" style="height:100%;" :loading="!loaded" transition="fade-transition">
            <v-card flat>
                <v-card-title>
                    Units
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="unitDialog = true" color="primary" style="text-transform:none">
                        <v-icon left>mdi-plus</v-icon>
                        Add new
                    </v-btn>
                </v-card-title>
                <v-card-subtitle>
                    These are all the units inside the community
                </v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <v-col xs="12" sm="12" md="12" lg="6" xl="6" offset-lg="6" offset-xl="6">
                            <v-text-field v-model="filterUnits" append-icon="mdi-magnify" label="Filter" clearable single-line hide-details></v-text-field>
                        </v-col>
                    </v-row>
                    <v-data-table :headers="headers" :items="units" :search="filterUnits" :items-per-page="25">
                        <template v-slot:item.more="{ item }">
                            <v-menu bottom left>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on">mdi-dots-vertical</v-icon>
                                </template>
                                <v-list dense>
                                    <v-list-item @click="editUnit(item)" dense>
                                        <v-list-item-content>
                                            Edit
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item @click="showUnitInfo(item)" dense>
                                        <v-list-item-content>
                                            information
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-icon>mdi-information-outline</v-icon>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-skeleton-loader>

        <unit ref="unit" :dialog="true" :dialogTitle="unitDialogTitle" :dialogSubtitle="unitDialogSubtitle" :dialogOpen="unitDialog" 
                    @cancel="unitDialog = false" @saved="updateTable" @update="updateTable"></unit>

        <unit-info ref="unitInfo" :dialog="true" :dialogOpen="unitInfoDialog" @cancel="unitInfoDialog = false"></unit-info>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Unit from '@/components/Unit.vue';
import UnitInfo from '@/components/UnitInfo.vue';

export default {
    data: () => ({
        loaded      : false,
        filterUnits : '',
        headers     : [
            { text: 'Unit', value: 'unitNumber', sortable: true },
            { text: 'Type', value: 'buildingType.name', sortable: true },
            { text: 'Status', value: 'status.name', sortable: true },
            { text: 'Actions', value: 'more', sortable: false, align: 'right' },
        ],

        unitDialogTitle: 'New Unit',
        unitDialogSubtitle: 'Add new unit to your community',
        unitDialog      : false,
        unitInfoDialog  : false,
    }),
    computed: {
        ...mapGetters({
            units       : 'units/units',
            needRefresh : 'units/needRefresh',
        }),
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions({
            loadUnits       : 'units/load',
            setNeedRefresh  : 'units/setNeedRefresh'
        }),
        async init() {
            try {
                if( this.units.length == 0 || this.needRefresh ) {
                    await this.loadUnits();
                }
            }
            catch(error) {
                this.mxShowMessage( error, 'error' );
            }
            finally {
                this.loaded = true;
            }
        },
        editUnit( unit ) {
            this.$refs.unit.load( unit );
            this.unitDialog = true;
        },
        showUnitInfo( unit ) {
            this.$refs.unitInfo.load( unit );
            this.unitInfoDialog = true;
        },
        updateTable() {
            this.setNeedRefresh( true );
            this.init();
        },
    },
    components: {
        Unit,
        UnitInfo
    }
}
</script>

<style lang="scss" scoped>

</style>