<template>
    <div>
        <div v-if="dialog">
            <v-dialog v-model="dialogOpen" min-width="350" max-width="350" persistent>
                <v-card flat ref="card">
                    <v-card-title>{{ dialogTitle }}</v-card-title>
                    <v-card-subtitle>{{ dialogSubtitle }}</v-card-subtitle>
                    <v-card-text>
                        <v-alert v-model="showAlert" :color="alertColor" border="left" colored-border dense>
                            <span class="warning--text">{{ alertMessage }}</span>
                        </v-alert>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field v-model="local.unitNumber" label="Unit Number" :error-messages="unitNumberError"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-select v-model="local.buildingTypeId" :items="buildingTypes" item-value="id" item-text="name" label="Unit Type" :error-messages="unitTypeError"></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-select v-model="local.statusId" :items="unitStatuses" item-value="id" item-text="name" label="Unit Status" :error-messages="unitStatusError"></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="cancel">Cancel</v-btn>
                        <v-btn text color="primary" @click="save" :loading="saving">{{ local.id == 0 ? 'Save' : 'update' }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
        <v-card flat class="ma-0 pa-0" v-else>
            <v-alert v-model="showAlert" :color="alertColor" border="left" colored-border dense>
                <span class="warning--text">{{ alertMessage }}</span>
            </v-alert>
            <v-card-text class="ma-0 pa-0">
                <v-row>
                    <v-col cols="12">
                        <v-text-field v-model="local.unitNumber" label="Unit Number" :error-messages="firstNameError"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-select v-model="local.buildingTypeId" :items="buildingTypes" item-value="id" item-text="name" label="Unit Type" :error-messages="unitTypeError"></v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-select v-model="local.statusId" :items="unitStatuses" item-value="id" item-text="name" label="Unit Status" :error-messages="unitTypeError"></v-select>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="cancel">Cancel</v-btn>
                <v-btn text color="primary" @click="save" :loading="saving">{{ local.id == 0 ? 'Save' : 'update' }}</v-btn>
            </v-card-actions>
        </v-card>    
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mask } from 'vue-the-mask'
import { API } from '@/inc/api';
import { _st } from '@/softech';

export default {
    props: {
        dialog: {
            type: Boolean,
            default: true
        },
        dialogTitle: {
            type: String,
            default: 'New Unit'
        },
        dialogSubtitle: {
            type: String,
            default: 'Add new unit to your community'
        },
        dialogOpen: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        unitStatusError: '',
        unitTypeError: '',
        unitNumberError: '',
        local: {
            id: 0,
            unitNumber: '',
            buildingTypeId: '',
            statusId: '',
        },
        saving: false,
        showAlert: false,
        alertMessage: '',
        alertColor: '',
    }),
    computed: {
        ...mapGetters({
            locationId      : 'auth/locationId',
            buildingTypes   : 'common/buildingTypes',
            unitStatuses    : 'common/unitStatuses'
        }),
        unit() {
            return {
                id: this.local.id,
                unitNumber: this.local.unitNumber,
                buildingTypeId: this.local.buildingTypeId,
                statusId: this.local.statusId,
            }
        }
    },
    watch: {
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
        },
        cancel() {
            this.$emit('cancel');
            this.local = {
                id: 0,
                unitNumber: '',
                buildingTypeId: '',
                statusId: '',
            };
            this.firstNameError = this.lastNameError = this.emailError = this.phoneError = this.unitNumberError = '';
            this.showAlert = false;
        },
        isValid() {
            let errorFound = false;
            this.unitNumberError = this.unitTypeError = this.unitStatusError = '';

            if( this.local.unitNumber.length == 0 ) {
                this.unitNumberError = 'required';
                errorFound = true;
            }
            if( this.local.buildingTypeId == 0 ) {
                this.unitTypeError = 'required';
                errorFound = true;
            }
            if( this.local.statusId == 0 ) {
                this.unitStatusError = 'required';
                errorFound = true;
            }

            return !errorFound;
        },
        load(r) {
            this.local = {
                id: r.id,
                unitNumber: r.unitNumber,
                buildingTypeId: r.buildingTypeId,
                statusId: r.statusId,
            };
        },
        async save() {
            if( !this.isValid() )
                return;

            try {
                let api = new API();

                this.saving = true;
                
                let res = null;
                if( _st.isNUE( this.local.id ) ) {
                    res = await api.post('/admin/unit', this.local );
                }
                else {
                    res = await api.put('/admin/unit', this.local );
                }

                this.saving = false;
                if( !res.data.status && res.data.error ) {
                    this.alertMessage = res.data.message;
                    this.alertColor = 'error';
                    this.showAlert = true;
                    return;
                }

                if( !res.data.status && !res.data.error ) {
                    this.alertMessage = res.data.message;
                    this.alertColor = 'warning';
                    this.showAlert = true;
                    return;
                }

                // is an update
                if( this.local.id != 0 ) {
                    this.$emit('update', res.data.data);
                    this.local.id = res.data.data.id;
                    this.cancel();
                    return;
                }

                this.$emit('saved', res.data.data);
                this.local.id = res.data.data.id;

                // show confirmation message
                
                this.cancel();
            }
            catch(error) {
                console.log(error);
            }
            finally {
                this.saving = false;
            }
        },
    },
    directives: {
      mask,
    },
}
</script>

<style scoped>

</style>