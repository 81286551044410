<template>
    <div>
        <div v-if="dialog">
            <v-dialog v-model="dialogOpen" min-width="600" max-width="600" persistent>
                <v-card flat ref="card">
                    <v-card-title>{{ dialogTitle }}</v-card-title>
                    <v-card-subtitle>{{ dialogSubtitle }}</v-card-subtitle>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <div class="d-flex flex-column grey lighten-2 pa-2 rounded align-center title">
                                    {{ unit.unitNumber }}
                                    <div class="caption">Unit number</div>
                                </div>
                            </v-col>
                            <v-col>
                                <div class="d-flex flex-column grey lighten-2 pa-2 rounded align-center title">
                                    {{ buildingType }}
                                    <div class="caption">Unit type</div>
                                </div>
                            </v-col>
                            <v-col>
                                <div class="d-flex flex-column grey lighten-2 pa-2 rounded align-center title">
                                    {{ status }}
                                    <div class="caption">Status</div>
                                </div>
                            </v-col>
                        </v-row>

                        <v-subheader class="px-0">Residents</v-subheader>
                        <!-- me quedé llenando este data table con los residentes que viven aquí -->
                        <v-data-table :headers="headers" :items="residents">
                            <template v-slot:item.avatar="{ item }">
                                <v-avatar size="35" color="teal">
                                    <img :src="item.avatarSrc" v-if="item.avatarSrc != null && item.avatarSrc.length > 0">
                                    <span class="white--text" v-else>{{ item.firstName[0].toUpperCase() + item.lastName[0].toUpperCase() }}</span>
                                </v-avatar>
                            </template>
                            <template v-slot:item.phone="{ item }">
                                {{ toPhoneNumber( item.phone ) }}
                            </template>
                        </v-data-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="cancel">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
        <v-card flat class="ma-0 pa-0" v-else>
            <v-alert v-model="showAlert" :color="alertColor" border="left" colored-border dense>
                <span class="warning--text">{{ alertMessage }}</span>
            </v-alert>
            <v-card-text>
                <v-row>
                    <v-col>
                        <div class="d-flex flex-column bg-grey rounded">
                            {{ unit.unitNumber }}
                            <div class="caption">Unit number</div>
                        </div>
                    </v-col>
                    <v-col>
                        <div class="d-flex flex-column bg-grey rounded">
                            {{ buildingType }}
                            <div class="caption">Unit type</div>
                        </div>
                    </v-col>
                    <v-col>
                        <div class="d-flex flex-column bg-grey rounded">
                            {{ status }}
                            <div class="caption">Status</div>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
           <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="cancel">Close</v-btn>
            </v-card-actions>
        </v-card>    
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { _st } from '@/softech';

export default {
    props: {
        dialog: {
            type: Boolean,
            default: true
        },
        dialogTitle: {
            type: String,
            default: 'Unit Information'
        },
        dialogSubtitle: {
            type: String,
            default: 'Access the unit latest information'
        },
        dialogOpen: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        unit        : {},
        residents   : [],

        headers     : [
            { text: '', value: 'avatar', sortable: false, },
            { text: 'Resident', value: 'fullName', sortable: true },
            { text: 'Phone', value: 'phone', sortable: true },
            { text: 'Email', value: 'email', sortable: true },
        ]
    }),
    computed: {
        buildingType() {
            return this.unit.buildingType?.name ?? '';
        },
        status() {
            return this.unit.status?.name ?? '';
        },
    },
    methods: {
        ...mapActions({
            getResidents    : 'units/getResidents',
        }),
        async load( unit ) {
            this.unit = unit;
            this.residents = await this.getResidents( unit.id );
        },
        cancel() {
            this.$emit('cancel');
            this.unit = {};
        },
        toPhoneNumber( val ) {
            return _st.toPhoneNumber( val );
        }
    }
}
</script>

<style lang="scss" scoped>

</style>